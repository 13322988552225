.user_info_popover .ant-popover-inner {
  padding: 0;
  margin-right: 10px;
}

.user_center .ant-menu-item {
  padding: 0 16px 0 !important;
}
.user_center .ant-menu-item .ant-menu-title-content {
  margin-left: 0px !important;
}
